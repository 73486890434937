import React, { useEffect, useState } from 'react';
import { Parallax } from 'react-spring/renderprops-addons.cjs';
import Layout from '@lekoarts/gatsby-theme-cara/src/components/layout';
import MenuBar from '../@lekoarts/gatsby-theme-cara/components/menu-bar';
import { ThemeProvider } from '@material-ui/core/styles';
import theme from '../@lekoarts/gatsby-theme-cara/constants/theme.js';
import AllServices from '../@lekoarts/gatsby-theme-cara/components/all-services';
import SEO from '@lekoarts/gatsby-theme-cara/src/components/seo';
import { graphql } from 'gatsby';

const ServicesPage = ({ data }) => {
  const [heightAdjust, setHeightAdjust] = useState(0);
  useEffect(() => {
    let adjustment = window.innerHeight < 800 ? 1 : 0;
    window.innerHeight > 900 && window.innerWidth > 1000 && (adjustment -= 0.4);
    window.innerHeight > 1200 && window.innerWidth > 2000 && (adjustment -= 0.6);
    window.innerHeight === 812 && (adjustment += 0.6);
    window.innerHeight === 896 && (adjustment += 0.1);
    window.innerHeight < 668 && (adjustment += 0.4);
    window.innerHeight < 600 && (adjustment += 1.5);
    setHeightAdjust(adjustment);
  }, []);
  return (
    <ThemeProvider theme={theme}>
      <SEO
        title="Services"
        description="See the services we offer for businesses. Our websites focus on SEO, mobile-responsiveness, accessibility and performance."
        pathname="/services"
      />
      <Layout>
        <Parallax pages={3 + heightAdjust}>
          <MenuBar />
          <AllServices offset={0} images={data} />
        </Parallax>
      </Layout>
    </ThemeProvider>
  );
};

export default ServicesPage;

export const servicesImage = graphql`
  fragment servicesImage on File {
    childImageSharp {
      fixed(height: 160) {
        ...GatsbyImageSharpFixed
      }
    }
  }
`;

export const query = graphql`
  query {
    image1: file(relativePath: { eq: "webdesign.png" }) {
      ...servicesImage
    }

    image2: file(relativePath: { eq: "notepad.png" }) {
      ...servicesImage
    }

    image3: file(relativePath: { eq: "smartphone.png" }) {
      ...servicesImage
    }
  }
`;
