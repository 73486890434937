/** @jsx jsx */
import { jsx } from 'theme-ui';
import styled from '@emotion/styled';
import Divider from '@lekoarts/gatsby-theme-cara/src/elements/divider';
import Inner from '@lekoarts/gatsby-theme-cara/src/elements/inner';
import Content from '@lekoarts/gatsby-theme-cara/src/elements/content';
import SVG from './svg';
import {
  UpDown,
  UpDownWide,
  waveAnimation,
} from '@lekoarts/gatsby-theme-cara/src/styles/animations';
import Footer from './footer';
import AllServicesMDX from '../sections/allservices.mdx';
import ServicesList from './services-list';
import { useEffect, useState } from 'react';

const InnerWave = styled.div`
  path {
    ${waveAnimation(`20s`)};
  }
`;

const AllServices = ({ offset, images }: { offset: number; images: any }) => {
  const [heightAdjust, setHeightAdjust] = useState(0);
  useEffect(() => {
    let adjustment = window.innerHeight < 800 ? 1 : 0;
    window.innerHeight > 900 && window.innerWidth > 1000 && (adjustment -= 0.3);
    window.innerHeight > 1200 && window.innerWidth > 2000 && (adjustment -= 0.7);
    window.innerHeight === 812 ? (adjustment += 0.8) : adjustment;
    window.innerHeight === 896 ? (adjustment += 0.2) : adjustment;
    window.innerHeight < 668 ? (adjustment += 0.5) : adjustment;
    window.innerHeight < 600 ? (adjustment += 1.4) : adjustment;
    setHeightAdjust(adjustment);
  }, []);
  return (
    <div>
      <Divider fill="divider" speed={0.2} offset={offset + 2 + heightAdjust}>
        <div
          sx={{
            position: `absolute`,
            bottom: 0,
            width: `full`,
            transform: `matrix(1, 0, 0, -1, 0, 0)`,
          }}
        >
          <InnerWave
            sx={{
              position: `relative`,
              height: `full`,
              svg: { width: `100%`, height: `40vh` },
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              id="contact-wave"
              viewBox="0 0 800 338.05"
              preserveAspectRatio="none"
            >
              <path>
                <animate
                  attributeName="d"
                  values="M 0 100 Q 250 50 400 200 Q 550 350 800 300 L 800 0 L 0 0 L 0 100 Z;M 0 100 Q 200 150 400 200 Q 600 250 800 300 L 800 0 L 0 0 L 0 100 Z;M 0 100 Q 150 350 400 200 Q 650 50 800 300 L 800 0 L 0 0 L 0 100 Z"
                  repeatCount="indefinite"
                  dur="30s"
                />
              </path>
            </svg>
          </InnerWave>
        </div>
      </Divider>

      <Content sx={{ zIndex: 99 }} speed={0.4} offset={offset + 0.1}>
        <Inner
          sx={{ position: 'absolute', top: '0', padding: '0 2rem' }}
          className="Services-container"
        >
          <AllServicesMDX />

          <p className="Services-body-text feature">
            Meet with us for a free consultation about how you envision your page. Do you
            want animation? Who is your audience? What is your company’s personality?
            Let’s discuss how to put your brand online and come up with a plan.
          </p>
          <ServicesList images={images} />
        </Inner>
      </Content>
      <Content speed={0.4} offset={offset + 2 + heightAdjust}>
        <Footer />
      </Content>

      <Divider speed={0.1} offset={offset}>
        <UpDown>
          <SVG
            icon="upDown"
            hiddenMobile
            width={8}
            color="icon_darkest"
            left="70%"
            top="20%"
          />
          <SVG icon="cone" width={24} color="#6FFFE9" left="40%" top="0" />
        </UpDown>
        <UpDownWide>
          <SVG
            icon="triangle"
            width={12}
            stroke
            color="icon_brightest"
            left="95%"
            top="50%"
          />
          <SVG icon="circle" width={6} color="icon_brightest" left="85%" top="15%" />
          <SVG
            icon="upDown"
            hiddenMobile
            width={8}
            color="icon_darkest"
            left="45%"
            top="10%"
          />
        </UpDownWide>
        <SVG icon="circle" width={6} color="icon_brightest" left="4%" top="20%" />
        <SVG icon="circle" width={12} color="icon_darkest" left="70%" top="60%" />
        <SVG icon="box" width={12} color="icon_darkest" left="20%" top="30%" />
        <SVG icon="hexa" width={8} stroke color="icon_darkest" left="80%" top="70%" />
        <SVG icon="triangle" width={8} stroke color="icon_darkest" left="25%" top="5%" />
      </Divider>
      <Divider speed={0.1} offset={offset + 0.7}>
        <UpDown>
          <SVG icon="box" hiddenMobile width={6} color="icon_blue" left="50%" top="75%" />
          <SVG
            icon="upDown"
            hiddenMobile
            width={8}
            color="icon_darkest"
            left="70%"
            top="20%"
          />
          <SVG
            icon="triangle"
            width={8}
            stroke
            color="icon_darkest"
            left="25%"
            top="5%"
          />
          <SVG icon="cone" hiddenMobile width={24} color="#6FFFE9" left="80%" top="80%" />
        </UpDown>
        <UpDownWide>
          <SVG
            icon="spiral"
            stroke
            hiddenMobile
            width={48}
            color="icon_pink"
            left="5%"
            top="80%"
          />
          <SVG
            icon="spiral"
            width={12}
            stroke
            color="icon_brightest"
            left="95%"
            top="50%"
          />
          <SVG
            icon="circle"
            hiddenMobile
            width={6}
            color="icon_brightest"
            left="85%"
            top="15%"
          />
          <SVG
            icon="spiral"
            stroke
            hiddenMobile
            width={8}
            color="icon_darkest"
            left="45%"
            top="10%"
          />
        </UpDownWide>
        <SVG
          icon="circle"
          hiddenMobile
          width={6}
          color="icon_brightest"
          left="4%"
          top="20%"
        />
        <SVG icon="circle" width={12} color="icon_darkest" left="70%" top="60%" />
        <SVG icon="spiral" stroke width={6} color="icon_orange" left="10%" top="10%" />
        <SVG icon="box" width={12} color="icon_darkest" left="20%" top="30%" />
        <SVG icon="hexa" width={8} stroke color="icon_darkest" left="80%" top="70%" />
      </Divider>
    </div>
  );
};

export default AllServices;
