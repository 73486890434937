/** @jsx jsx */
import { jsx } from 'theme-ui';
import Grid from '@material-ui/core/Grid';
import Img from 'gatsby-image';

const ServicesList = ({ images }) => (
  <Grid container spacing={10} className="Services-page">
    <Grid container spacing={10}>
      <Grid item xs={12} sm={6}>
        <h3 className="Services-header-text">Web Design</h3>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Img
          fixed={images.image1.childImageSharp.fixed}
          alt="monitor and paintbrush"
          className="Services-details-image"
        />
      </Grid>
    </Grid>
    <p className="Services-body-text">
      You should be getting the maximum potential out of your web presence. We want to
      work with you to upgrade your website or create an entirely new one.
    </p>
    <p className="Services-body-text">
      If you decide to go with us, we will meet with you to gather pictures and
      information about you and your business, because authenticity matters.
    </p>
    <p className="Services-body-text">
      Then we put it all together and show you a first draft. You tell us what you like
      about it, and what’s not working. Maybe something important to you is missing. Maybe
      we used an artistic technique that you'd like used more. We want to hear your
      opinions so we can make changes based on your feedback.
    </p>
    <p className="Services-body-text">
      We do this process as many times as it takes until you are satisfied. Then the
      website goes live, and you watch the clicks come in!
    </p>
    <Grid container spacing={10}>
      <Grid item xs={12} sm={6}>
        <h3 className="Services-header-text">Content Creation</h3>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Img
          fixed={images.image2.childImageSharp.fixed}
          alt="notepad"
          className="Services-details-image"
        />
      </Grid>
    </Grid>
    <p className="Services-body-text">
      What does your business mean to you? That’s what we want to put on your website. And
      your blog. And your social media. We use a blend of compelling visuals and media
      writing to share your passion with others.
    </p>
    <p className="Services-body-text">
      <span className="bold">Visuals:</span> We will help you find the most appealing
      pictures to visualize your story, and take some more for you if you don’t have
      enough.
    </p>
    <p className="Services-body-text">
      <span className="bold">Writing:</span> You have a story to tell, we want to tell it.
      With our background in Media communications, we write for the
      web in a way that grabs attention, gets to the point and enhances your SEO(Search
      Engine Optimization).
    </p>
    <p className="Services-body-text">
      <span className="bold">Video:</span> Sometimes a short video can capture the heart
      of what you do better than one still shot or a bunch of words. We have experience
      with shooting and editing video and would be happy to do it for you!
    </p>
    <Grid container spacing={10}>
      <Grid item xs={12} sm={6}>
        <h3 className="Services-header-text">Mobile Apps</h3>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Img
          fixed={images.image3.childImageSharp.fixed}
          alt="smartphone"
          className="Services-details-image"
        />
      </Grid>
    </Grid>
    <p className="Services-body-text">
      Would you like your business to have a mobile app in the App Store and/or Play
      Store?
    </p>
    <p className="Services-body-text">
      We will build anything from an app version of your website to a fully customized
      native app in iOS and/or Android.
    </p>
    <p className="Services-body-text">
      Not sure whether you want or need an app? All of our websites are{' '}
      <a href="https://www.freecodecamp.org/news/progressive-web-apps-101-the-what-why-and-how-4aa5e9065ac2/">
        Progressive Web Apps (PWAs)
      </a>{' '}
      which means you can benefit from the ability to install your website as a native
      mobile app. This will also allow you to send push notifications to your customers'
      phones with promotions and updates. For more info on progressive web apps you can{' '}
      <a href="https://www.swirlwebdesign.com/blog/progressive-web-apps/">
        check out our blog.
      </a>
    </p>
  </Grid>
);

export default ServicesList;
